<template>
  <div class="about">
    <img
      alt="Bundesarchiv fuer Einheitsbewaeltigung Logo"
      src="../assets/logo.png"
    />
    <h1>Wollen Sie einen weiteren Bon einscannen?</h1>

    <div class="flex-column">
      <button @click="handlesubmit" autofocus>JA</button>
      <button @click="handleAbbrechen">NEIN</button>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";


export default {
  name: "SlideEins",
  props: {},
  setup() {
    const router = useRouter();
    

    const handleAbbrechen = () => {
      router.push("/SlideEins");
    };

    const handlesubmit = () => {
      router.push("/SlideEins");
    };

    return { handlesubmit, handleAbbrechen };
  },
};
</script>