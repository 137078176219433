
<template>
  <div class="hello">
    <img
      alt="Bundesarchiv fuer Einheitsbewaeltigung Logo"
      src="../assets/logo.png"
    />
    <h3>
      Bitte wählen Sie einen Akteur, Betrieb oder Ort für Ihren Folgeauftrag
      aus:
    </h3>
    <hr />
    {{ $store.state.KeysUnlocked }}
    <br />
    <ul>
      <div v-for="auftrag in erfassung" :key="auftrag">
        <li
          v-if="auftrag['Button_Label'].length > 0"
          style="display: flex; justify-content: space-between; margin: 10px"
          :class="{ activeListItem: auftragIsClickable(auftrag.Key) }"
        >
          <span>
            {{ auftrag["Button_Label"] }}
          </span>
          <button
            v-if="auftragIsClickable(auftrag.Key)"
            @click="handleFolgeauftrag($event, auftrag.Key)"
          >
            ALS FOLGEAUFTRAG AUSWÄHLEN
          </button>
        </li>
      </div>
    </ul>
  </div>
  <br />
  <hr />
  <div>
    <button @click="$router.push('/SlideEins')">
      WEITERE ZEITBONS SCANNEN
    </button>
  </div>
</template>

<script>
import { ref } from "@vue/runtime-core";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import erfassung from "@/assets/erfassung.json";

export default {
  name: "SlideVier",
  props: {},
  setup() {
    const router = useRouter();
    const store = useStore();
    const barcode = ref(null);

    const auftragIsClickable = (auftragsKey) => {
      console.log(auftragsKey); // CONTINUE HERE
      if (store.state.KeysUnlocked.find((e) => e === auftragsKey)) {
        return true
      } else {
        return false
      }
    }

    const handleFolgeauftrag = async (e,key) => {
      console.log(key);
      // TODO: send HTTP REQUEST TO PRINTER with filename in it
      router.push("/Done");
    }

    return { erfassung, barcode, auftragIsClickable, handleFolgeauftrag };
  },
};
</script>
