<template>
  <div class="about">
    <img
      alt="Bundesarchiv fuer Einheitsbewaeltigung Logo"
      src="../assets/logo.png"
    />
    <h1>Bitte Zeitbon scannen oder Code eingeben.</h1>

    <div class="flex-column">
      <input
        type="text"
        ref="barcode"
        @keydown.enter="handlesubmit"
        placeholder="Zeitbon Code"
        autofocus
      />
      <button @click="handlesubmit">OK</button>
      <button
        v-if="$store.state.foundZeitbons.length > 0"
        @click="handleAbbrechen"
      >
        Zeiterfassung abschließen
      </button>
    </div>

    <br />
    <hr />
    <br />

    <!-- <div v-if="$store.state.foundZeitbons.length > 0">
      {{ $store.state.foundZeitbons }}
    </div> -->

  </div>
</template>

<script>
import { ref } from "@vue/runtime-core";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
//import erfassung from "@/assets/erfassung.json";
import slides from "@/assets/slides.json";

export default {
  name: "SlideEins",
  props: {},
  setup() {
    const router = useRouter();
    const store = useStore();
    const barcode = ref(null);

    const handleAbbrechen = () => {
      router.push("/SlideVier");
    };

    const handlesubmit = () => {
      let inputvalue = barcode.value.value;
      if (inputvalue) {
        let match = slides.find((e) => e.Code == inputvalue);
        if (match) {
          if (store.state.foundZeitbons.find((s) => s.Code == match.Code)){
            alert(
              "Diese Zeitdatei wurde bereits inventarisiert. Zeiterfassung abbrechen?"
            );
            return;
          }
          console.log("found", match);
          store.state.foundZeitbons.push(match);
          store.state.lastAdded = match;
          store.dispatch("updateKeys", match );
          router.push("/SlideZwei");
        } else {
          alert("Kein gültiger Zeitbon-Code erkannt. Bitte Zeitbon scannen oder Code eingeben.")
        }
      }
    };

    return {barcode, handlesubmit, handleAbbrechen };
  },
};
</script>