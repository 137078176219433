<template>
  <div class="home">
    <img
      alt="Bundesarchiv fuer Einheitsbewaeltigung Logo"
      src="../assets/logo.png"
    />
    <SlideNull msg="Herzlich Willkommen. Bitte Auftragsnummer eingeben." />
  </div>
</template>

<script>
// @ is an alias to /src
import SlideNull from "@/components/SlideNull.vue";

export default {
  name: "Home",
  components: {
    SlideNull,
  },
};
</script>
