import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import SlideEins from "../views/SlideEins.vue";
import SlideZwei from "../views/SlideZwei.vue";
import SlideDrei from "../views/SlideDrei.vue";
import SlideVier from "../views/SlideVier.vue";
import Done from "../views/Done.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/SlideEins",
    name: "SlideEins",
    component: SlideEins
  },
  {
    path: "/SlideZwei",
    name: "SlideZwei",
    component: SlideZwei
  },
  {
    path: "/SlideDrei",
    name: "SlideDrei",
    component: SlideDrei
  },
  {
    path: "/SlideVier",
    name: "SlideVier",
    component: SlideVier
  },
  {
    path: "/Done",
    name: "Done",
    component: Done
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
