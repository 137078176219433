<template>
  <div class="hello">
    <h1>{{ msg }}</h1>
    <div class="flex-column">
      <input type="text" v-model="auftragsnummer" autofocus
      placeholder="5-stellige Auftragsnummer in ein Feld: _ _ - _ _ _ _ _ (Auftragsnr. z.B. AT-12345)"
      >
      <button @click="handlesubmit">BESTÄTIGEN</button>
    </div>
  </div>
</template>

<script>
import { ref } from "@vue/runtime-core";
import { useRouter } from "vue-router";


export default {
  name: "SlideNull",
  props: {
    msg: String,
  },
  setup() {
    const router = useRouter();
    const korrekteAuftragsnummer = "12345";
    const auftragsnummer = ref("");

    const handlesubmit = () => {
      if (auftragsnummer.value.length < 5) {
        alert("Auftragsnummer zu kurz.");
        return;
      } else if (auftragsnummer.value !== korrekteAuftragsnummer) {
        alert(
          "Keine gültige Auftragsnummer gefunden. Bitte geben Sie die Auftragsnummer von Ihrem Auftragsbogen ein."
        );
        return;
      }
      alert("Auftrag gefunden.");
      router.push("/SlideEins");
    };

    return { auftragsnummer, handlesubmit };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
