import { createStore } from "vuex";

export default createStore({
  state: {
    foundZeitbons: [],
    KeysUnlocked: [],
    lastAdded: null,
  },
  mutations: {},
  actions: {
    updateKeys: async ({ state }, bon) => {
      console.log("updating keys with", bon.Keys);
      let keys = bon.Keys.split(",");
      for (let k of keys){
        k = k.trim();
        if (!state.KeysUnlocked.find((e) => e === k)){
         state.KeysUnlocked.push(k);
        }
      }
    },
  },
  modules: {},
});
