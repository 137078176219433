<template>
  <div class="about">
    <img
      alt="Bundesarchiv fuer Einheitsbewaeltigung Logo"
      src="../assets/logo.png"
    />
    <h1>
      {{ $store.state.lastAdded["Header"] }}
      <br />
      <br />
      {{ $store.state.lastAdded["Body"] }}
    </h1>

    <div class="flex-column">
      <button @click="handlesubmit" autofocus>WEITER</button>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { useStore } from "vuex";

export default {
  name: "SlideEins",
  props: {},
  setup() {
    const router = useRouter();
    const store = useStore();

    const handleAbbrechen = () => {
      router.push("/");
    };

    const handlesubmit = () => {
      router.push("/SlideEins");
    };

    return { store, handlesubmit, handleAbbrechen };
  },
};
</script>