<template>
  <div class="hello">
    <img
      alt="Bundesarchiv fuer Einheitsbewaeltigung Logo"
      src="../assets/logo.png"
    />
    <h3>
     Vielen Danke für Ihre Mitarbeit. Bitte vergessen Sie nicht Ihren Folgeauftrag aus dem Drucker zu entnehmen. Für heute können Sie Feierabend machen. Bitte geben sie Ihre Kopfhörer und das Zeitsichtungsgerät an der im Nebenraum Bar ab. Sie können auch unser Archiv für eine vertiefende Lektüre konsultieren.
    </h3>
    <hr />
    <br />
    
  </div>
</template>


